.simple-wysiwyg-editor {
  /**
       *
       * Lists
       *
       */

  dl,
  ol,
  ul {
    list-style: none;
    margin-top: 0;
    padding-left: 0;

    dl,
    ol,
    ul {
      font-size: 100%;
      margin: 1.5rem 0 1.5rem 3rem;
    }
  }

  ol {
    list-style: decimal inside;
    li {
      list-style-type: decimal;
    }
  }

  ul {
    list-style: disc inside;
    li {
      list-style-type: disc;
    }
  }

  ol,
  ul {
    li[role="checkbox"] {
      list-style-type: none;
    }
  }

  ul,
  ol {
    ul,
    ol {
      margin: 0rem 0 0.5rem 1.5rem;
    }
  }

  h1 {
    font-size: 1.4em;
    font-weight: bold;
  }
  h2 {
    font-size: 1.2em;
    font-weight: bold;
  }
  h3 {
    font-size: 1.1em;
    font-weight: bold;
  }
  h4 {
    font-size: 1.05em;
    font-weight: bold;
  }
  h5 {
    font-size: 1em;
    font-weight: bold;
  }
  h6 {
    font-size: 1em;
    font-weight: 600;
  }
  p {
    margin-top: 0.8em;
    margin-bottom: 0.8em;
    margin: 0px;
  }
  dl,
  ol,
  ul {
    list-style: none;
    margin-top: 0;
    padding-left: 0;
  }
  dl dl,
  dl ol,
  dl ul,
  ol dl,
  ol ol,
  ol ul,
  ul dl,
  ul ol,
  ul ul {
    font-size: 100%;
    margin: 1.5rem 0 1.5rem 3rem;
  }
  ol {
    list-style: decimal inside;
  }
  ul {
    list-style: disc inside;
    margin-left: 2em;
  }
  ul ul,
  ul ol,
  ol ul,
  ol ol {
    margin: 0rem 0 0.5rem 1.5rem;
  }
  blockquote {
    border-left: 0.3rem solid #d1d1d1;
    margin-left: 0;
    margin-right: 0;
    padding: 1rem 1.5rem;
    margin: 0;
  }
}

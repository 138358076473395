.ant-select-selection-item {
  .ant-select-selection-item-content {
    padding-right: 10px;
  }
  .ant-avatar {
    transform: scale(0.7);
  }
  .description {
    display: none;
  }
}

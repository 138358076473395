body {
  background-color: #f2f2f2;
}
.form-config-container {
  width: 400px;
}

.form-container {
  width: 100%;
  min-width: 320px;
  max-width: 640px;
}
.collapsible_open {
  height: auto;
  animation-name: collapsible_open;
  animation-duration: 0.5s;
  overflow: hidden;
}

@keyframes collapsible_open {
  from {
    height: 0;
    visibility: hidden;
  }
  to {
    height: 100px;
    visibility: visible;
  }
}
.collapsible_close {
  height: 0;
  visibility: hidden;
  animation-name: collapsible_close;
  animation-duration: 0.5s;
  overflow: hidden;
}

@keyframes collapsible_close {
  from {
    height: 100px;
    visibility: visible;
  }
  to {
    height: 0;
    visibility: hidden;
  }
}
.ant-switch-inner {
  display: flex;
  align-items: center;
}

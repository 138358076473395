.styled-menu-container {
  .ant-menu-item {
    border-radius: 10px;
  }

  .ant-menu-item,
  .ant-menu-submenu-title {
    font-weight: bold;
    margin-right: 12px;
  }
}

.contact-list-item {
  .name-line,
  .preview-line {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .text-container {
    width: 100%;
    overflow: hidden;
    .date {
      min-width: 52px;
    }
  }
  &.active {
    background-color: #ebf4ff;
    .name-line,
    .subject-line {
      font-weight: bold;
    }
    .subject-line {
      color: #1a73e8;
    }
  }
}

.quote-editor-modal {
  .dnd-item {
    .delete-icon {
      opacity: 0;
      &:hover {
        opacity: 1;
      }
    }
    &:hover {
      .delete-icon {
        opacity: 0.7;
      }
    }
  }
}

.reply-input-container,
.reply-input-container:hover {
  outline: none;

  .w-full__highlighter > *:not(:last-child) {
    position: relative;
  }

  .w-full__highlighter > *:not(:last-child):after {
    content: "";
    display: block;
    background-color: yellow;
    position: absolute;
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    top: -3px;
    left: -3px;
    border-radius: 20px;
    overflow: hidden;
  }

  .send-input {
    textarea:focus {
      outline: none;
    }
  }
  .w-full__suggestions {
    width: 100% !important;
    left: 0px !important;
    top: auto !important;
    bottom: 0px !important;
    border: 1px solid #f4f4f4 !important;
    padding: 5px !important;
    margin-top: 0px !important;
    position: absolute !important;
    max-height: 300px;
    overflow: auto;
    .w-full__suggestions__item {
      padding: 5px;
      border-bottom: 1px solid #f4f4f4;
    }
    .w-full__suggestions__item--focused {
      background-color: #f4f4f4;
    }
  }

  .w-full__control {
    textarea {
      outline: none;
    }
  }

  textarea.w-full__input {
    outline: none;
  }

  .drop-indicator {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #ffffff77;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2em;
    z-index: 9;
  }

  .draft-js-wrapper .draft-js-editor {
    min-height: 200px;
    padding: 10px;
  }
}

.mode-dark {
  .reply-input-container,
  .reply-input-container:hover {
    .w-full__suggestions {
      background: #000 !important;
      border: 1px solid #161c25 !important;
      .w-full__suggestions__item {
        border-bottom: 1px solid #161c25;
      }
      .w-full__suggestions__item--focused {
        background-color: #161c25;
      }
    }
  }
}

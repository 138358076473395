.custom-data-container {
  // padding: 20px;
  table {
    .label {
      line-height: 1.5em;
      min-width: 100px;
      max-width: 100px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 5px;
      border-radius: 1px;
      margin-right: 5px;
    }
  }
}

.article-list {
  .article-list-table {
    // td {
    //   background: none !important;
    // }
    // tr:nth-child(even) {
    //   background-color: #f7f7f7;
    // }
    tr:hover {
      background-color: #f4f4f4;
      cursor: pointer;
      td {
        background: none !important;
      }
    }
    .ant-table-tbody > tr > td {
      padding: 5px;
    }
  }
  .search-bar {
    .ant-input-group-addon {
      .ant-btn {
        border-radius: 0px !important;
      }
    }
    .filter-button {
      // height: 40px;
      height: 100%;
      width: 50px;
      background: #f4f4f4;
      border-radius: 0 5px 5px 0;
      margin-left: -2px;
      border: 1px solid #d9d9d9;
      border-left: 0px;
    }
    .ant-btn-icon-only.ant-btn-lg {
      height: 2.9rem;
    }
    .ant-input-search .ant-input-lg {
      height: 2.9rem;
    }
  }
}

.mode-dark {
  .article-list {
    .article-list-table {
      tr:hover {
        background-color: #d9d9d959;
      }
    }
    .search-bar {
      .filter-button {
        background: #0000001c;
        border: 1px solid #ffffff1c;
        border-left: 0px;
      }
    }
  }
}

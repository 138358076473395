.styled-accordion-container {
  .ant-collapse {
    background: none;
    .ant-table-thead > tr > th,
    .ant-table-thead > tr > td {
      background: transparent;
    }
    .ant-table {
      background: transparent;
    }
  }
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    top: 15px;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-weight: bold;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .ant-collapse > .ant-collapse-item {
    margin-bottom: 10px;
    background: #f4f4f4;
    border-radius: 10px;
    border: none;
  }
  .ant-collapse-content {
    border-top: none;
    background: #f9f9f9;
  }
}

.styled-accordion-container-2 {
  .ant-collapse {
    background: none;
    .ant-table-thead > tr > th,
    .ant-table-thead > tr > td {
      background: transparent;
    }
    .ant-table {
      background: transparent;
    }
  }
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    top: 15px;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-weight: bold;
    padding-top: 20px;
    padding-bottom: 20px;
    background: #fff;
    border: 1px solid #eaeaea;
    border-radius: 10px;
  }
  .ant-collapse > .ant-collapse-item {
    margin-bottom: 10px;
    background: #f4f4f4;
    border-radius: 10px;
    border: none;
  }
  .ant-collapse-content {
    border-top: none;
    background: #f9f9f9;
  }
}

.styled-accordion-container-3 {
  .ant-collapse {
    background: none;
    .ant-table-thead > tr > th,
    .ant-table-thead > tr > td {
      background: transparent;
    }
    .ant-table {
      background: transparent;
    }
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-weight: bold;
    background: #fff;
    border: 0;
  }
}

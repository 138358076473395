.contact-picker {
  .contacts-container {
    height: 300px;
    overflow: auto;
  }
  .add-button {
    width: 40px;
    background: #f4f4f4;
    border-radius: 0 5px 5px 0;
    margin-left: -2px;
    border: 1px solid #d9d9d9;
    border-left: 0px;
  }
}

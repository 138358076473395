.notification-item {
  .archive-button {
    opacity: 0;
  }
  &:hover {
    .archive-button {
      opacity: 1;
    }
  }
}

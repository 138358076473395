.conversation-list-container {
  .ant-segmented {
    background-color: #f0f0f0;
    .ant-segmented-group {
      border: 1px solid #dbdbdb;
    }
    .ant-segmented-item-selected {
      font-weight: bold;
    }
  }

  .chat-list-spin-container {
    .ant-spin-container {
      height: 100%;
      width: 100%;
      display: flex;
      flex: 1;
    }
  }
}

.mode-dark {
  .conversation-list-container {
    .ant-segmented {
      background-color: #000000;
      .ant-segmented-group {
        border: 1px solid transparent;
      }
      .ant-segmented-item-selected {
        background-color: #282828;
      }
    }
  }
}

.user-avatar {
  font-weight: 800;
  position: relative;
  display: flex;
  &:before {
    content: "";
    position: absolute;
    bottom: 5%;
    right: 5%;
    width: 30%;
    height: 30%;
    max-width: 15px;
    max-height: 15px;
    border-radius: 100%;
    border: 2px solid #fff;
    background: #f3f3f3;
    z-index: 5;
  }

  &.online:before {
    background-color: #4caf50;
  }

  &.facebook {
    img {
      opacity: 1;
    }

    mat-icon {
      position: absolute;
      right: 0;
      bottom: -5px;
      background: #fff;
      border-radius: 5px;
    }

    &:before {
      background-color: none;
      background: none;
      border: none;
    }
  }

  &.offline {
    img {
      opacity: 0.8;
    }

    &:before {
      background-color: #f44336;
    }
  }

  &.busy {
    img {
      opacity: 0.8;
    }

    &:before {
      background-color: #f44336;
    }
  }

  &.away {
    img {
      opacity: 0.8;
    }

    &:before {
      background-color: #ffc107;
    }
  }

  &.none {
    img {
      opacity: 0.8;
    }

    &:before {
      background-color: #646464;
    }
  }
  .medium-icon {
    position: absolute;
    bottom: 5%;
    right: 5%;
    width: 35%;
    height: 35%;
    max-width: 15px;
    max-height: 15px;
    border-radius: 2px;
    border: 2px solid #fff;
    background: #fff;
    z-index: 6;
    display: flex;
    justify-content: center;
    align-items: center;
    &.facebook {
      color: #1b73e7;
    }
    &.email {
      color: #2d3748;
    }
    &.whatsapp {
      color: #3dc350;
      border-radius: 5px;
    }
  }
}

.mode-dark {
  .user-avatar {
    &:before {
      border: 2px solid #121620;
    }
  }
  .ant-avatar-group .ant-avatar {
    border: 1px solid #ffffff52;
  }
}

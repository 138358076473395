@import "~animate.css/animate.min.css";

.animate-spin {
  animation-name: spin;
  animation-duration: 800ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.fadeInLeftMin {
  animation-name: fadeInLeftMin;
  animation-duration: 400ms;
}

@keyframes fadeInLeftMin {
  0% {
    opacity: 0;
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0);
  }
}

.fadeInRightMin {
  animation-name: fadeInRightMin;
  animation-duration: 400ms;
}

@keyframes fadeInRightMin {
  0% {
    opacity: 0;
    transform: translate3d(+20px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

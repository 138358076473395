.account-configurations {
  .list-details {
    margin-bottom: 5px;
    border-radius: 5px;
    padding: 10px;
    &:hover {
      background: #f4f4f4;
    }
    &.editable-field {
      cursor: pointer;
      .edit-icon {
        visibility: hidden !important;
      }
      &:hover {
        .edit-icon {
          visibility: visible !important;
        }
      }
    }
  }

  .cc-logo {
    width: 200px;
  }

  .account-manager-avatar {
    width: 200px;
  }
}

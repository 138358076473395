.ant-layout-sider-collapsed {
  .hide-on-collapsed {
    display: none !important;
  }
}

.show-on-collapsed {
  display: none !important;
}

.ant-layout-sider-collapsed {
  .show-on-collapsed {
    display: block !important;
  }
}

.ant-layout-sider-collapsed {
  .inbox-panel {
    .ant-menu-item {
      margin-right: 0px !important;
    }
    .ant-menu-submenu {
      span {
        display: none;
      }
    }
    .ant-menu-submenu:hover {
      background-color: #fff;
      border-radius: 10px;
    }
    .manage-channel-link {
      height: 0px;
      padding: 0px;
      opacity: 0;
    }
    .inboxes-title {
      height: 0px;
      margin-bottom: 0rem;
      opacity: 0;
    }

    .new-button-container-big {
      padding: 0rem;
      height: 0px;
      opacity: 0;
      .help-text {
        display: none;
      }
    }

    .new-button-container-small {
      height: 80px;
      padding: 1.25rem;
      opacity: 1;
    }

    .ant-menu-item-icon {
      .ant-badge {
        position: absolute;
        right: 0px;
        .ant-badge-dot {
          right: 15px;
          top: 15px;
        }
      }
    }
  }
}

.inbox-panel {
  .manage-channel-link {
    height: 48px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    opacity: 1;
    overflow: hidden;
    transition: all 0.2s linear;
  }
  .inboxes-title {
    height: 31px;
    margin-bottom: 1rem;
    opacity: 1;
    overflow: hidden;
    transition: all 0.2s linear;
  }
  .new-button-container-big {
    padding: 2rem;
    height: 134px;
    opacity: 1;
    overflow: hidden;
    transition: all 0.2s linear;
  }

  .new-button-container-small {
    height: 0px;
    padding: 0rem;
    opacity: 0;
    overflow: hidden;
    transition: all 0.2s linear;
  }

  .ant-menu-item .ant-menu-item-icon + span {
    width: 100%;
    display: contents;
    position: relative;
    .ant-badge {
      position: absolute;
      right: 5px;
      top: 10px;
      .ant-badge-dot {
        top: 8px;
        right: 5px;
      }
    }
  }
  .ant-menu-inline-collapsed {
    .ant-menu-item .ant-menu-item-icon + span {
      display: none;
    }
  }
  .ant-menu-submenu-title {
    i {
      padding-right: 10px;
    }
  }
  .ant-menu-item {
    i {
      display: inline-flex;
    }
  }

  .ant-menu-item-selected {
    border-radius: 10px;
  }

  .ant-menu-item,
  .ant-menu-submenu-title {
    font-weight: bold;
    margin-right: 12px;
  }
  .ant-menu-item:hover {
    background-color: rgba(226, 232, 240, 0.1);
    border-radius: 10px;
  }

  .ant-menu-root.ant-menu-vertical,
  .ant-menu-root.ant-menu-vertical-left,
  .ant-menu-root.ant-menu-vertical-right,
  .ant-menu-root.ant-menu-inline {
    padding-left: 7px;
    padding-right: 7px;
  }

  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    border-right: 0px;
    border-left: 0px;
  }

  // Start Resizing Panel
  .SplitPane > .Pane {
    overflow: auto;
  }
  .Resizer {
    background: #000;
    opacity: 0.2;
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
  }

  .Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
  }

  .Resizer.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
  }

  .Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
  }

  .Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
  }

  .Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
  }
  .Resizer.disabled {
    cursor: not-allowed;
  }
  .Resizer.disabled:hover {
    border-color: transparent;
  }

  // End of Resizer

  .inbox-item-menu {
    float: right;
    right: 0;
    opacity: 0;
    position: absolute;
  }
  .ant-menu-item:hover {
    .inbox-item-menu {
      opacity: 1;
    }
  }
}

.mode-dark {
  .inbox-panel {
    .ant-menu-item:hover {
      background-color: #00000024;
    }
    .ant-menu-submenu:hover {
      background-color: #00000024;
    }
  }
}

.styled-accordion-container {
  .ant-collapse > .ant-collapse-item {
    background: #141414;
  }
  .ant-collapse-content {
    background: #000000;
  }
}

.styled-accordion-container-2 {
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    background: #141414;
    border: 1px solid #eaeaea;
  }
  .ant-collapse-content {
    background: #000000;
  }
}

.styled-accordion-container-3 {
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    background: #141414;
  }
}

body {
  background: #19212e;
  color: rgba(255, 255, 255, 0.85);
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background-color: #0d0d0e;
  opacity: 0.3;
}

// Scroll Bar Modifications
::-webkit-scrollbar-thumb {
  background-color: #ffffff28;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #ffffff38;
}

// Auto Fill Color Patch
/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0px solid black !important;
  -webkit-text-fill-color: white !important;
  -webkit-box-shadow: 0 0 0px 1000px #333333 inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
}

.change-logs-modal {
  .ant-modal-body {
    padding: 0;
  }

  dl,
  ol,
  ul {
    list-style: none;
    margin-top: 0;
    padding-left: 0;
  }
  dl dl,
  dl ol,
  dl ul,
  ol dl,
  ol ol,
  ol ul,
  ul dl,
  ul ol,
  ul ul {
    font-size: 100%;
    margin: 1.5rem 0 1.5rem 3rem;
  }
  ol {
    list-style: decimal inside;
  }
  ul {
    list-style: disc inside;
  }
  ul ul,
  ul ol,
  ol ul,
  ol ol {
    margin: 0rem 0 0.5rem 1.5rem;
  }

  p {
    margin-bottom: 2em;
  }
}

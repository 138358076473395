.conversation-picker {
  .conversation-list-container {
    width: 100% !important;
    .header {
      width: 100% !important;
    }
  }

  .chat-list-container {
    min-height: 65vh;
  }
}

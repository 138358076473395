.config-collapsible {
  .ant-collapse {
    background: none;
    margin-right: 10px;
    .ant-collapse-item {
      border: 1px solid #d9d9d9;
      margin-top: 15px;
      margin-bottom: 15px;

      border-radius: 10px !important;
      overflow: hidden;
      .ant-collapse-header {
        background: none;
      }
    }
  }
}

.mode-dark {
  .ant-collapse {
    .ant-collapse-item {
      border: 1px solid #ffffff1e;
    }
  }
}

@import "./styles/global";
@import "./styles/animations";

body,
#root,
.ant-modal-root,
.ant-image-preview-root {
  @import "./styles/dark-mode-transitions";

  @import "./styles/utils";
  @import "./styles/component-utils";
  @import "./styles/ant-patches";
  // Added here since since we needed strong css selectors
  @import "./styles/raw-html-style";

  .route-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .route-wrapper > div {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: auto;
  }
}

.mode-dark {
  @import "./styles/global.dark";
  body,
  #root,
  .ant-modal-root,
  .ant-image-preview-root {
    @import "./styles/utils.dark";
    @import "./styles/component-utils.dark";
    @import "./styles/ant-patches.dark";
  }
}

.report-widget {
  position: relative;
  overflow: auto;
  .widget-title {
    i {
      vertical-align: -2px;
      margin-right: 5px;
    }
  }

  .ant-table-wrapper {
    .ant-spin-nested-loading {
      .ant-spin-container {
        flex-direction: column;
      }
    }
  }

  .ant-table-container {
    // min-height: 430px;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    padding-left: 35px;
  }
  .ant-table-container table > tbody tr td:first-child {
    padding-left: 45px;
  }

  .ant-table-container table > tbody tr td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-table {
    width: 100%;
  }

  .ant-table tbody tr {
    cursor: pointer;
  }

  .menu-background {
    display: block;
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0, 0, 0, 0);
    top: 0px;
    left: 0px;
    border-radius: 10px;
    z-index: 9;
    .menu-container {
      border: 1px solid rgb(244, 244, 244);
      background: #fff;
      border-radius: 10px;
      overflow: hidden;
      width: 300px;
    }
  }
}

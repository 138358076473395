.bulk-add-contacts-modal {
  .drag-and-drop-container {
    .ant-upload-drag {
      .ant-upload-btn {
        position: absolute;
      }
    }
    .ant-upload-list {
      display: none;
    }
  }
}

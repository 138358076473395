.manage-custom-views-container {
  .row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
    z-index: 999999;
  }

  .row-dragging td {
    padding: 16px;
    visibility: hidden;
  }

  .row-dragging .drag-visible {
    visibility: visible;
  }

  .preset-text {
    white-space: break-spaces;
  }

  .ant-table-title {
    background: #f4f4f4;
    border-radius: 10px;
  }
}

.mode-dark {
  .manage-custom-views-container {
    .ant-table-title {
      background: #0000001f;
    }
  }
}

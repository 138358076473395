.edit-report-widget-drawer.ant-drawer-content {
  overflow: inherit;
  .ant-drawer-body {
    overflow: visible;

    [id^="headlessui-popover-panel-"] {
      .ant-col {
        min-height: auto;
      }
    }
  }
}

.new-message-modal {
  .send-input {
    min-height: 150px;
  }
}

.mode-dark {
  .new-message-modal {
    .reply-input-container {
      background: #ffffff0a;
    }
  }
}

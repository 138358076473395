.multi-user-selector {
  &.with-pill {
    .ant-select-selection-item {
      height: 50px !important;
      line-height: 50px !important;
      margin: 6px;
      .ant-select-selection-item-content {
        padding-right: 10px;
      }
      .ant-avatar {
        transform: scale(0.7);
      }
      .description {
        display: none;
      }
    }
    .ant-select-selection-item-remove {
      .anticon-close {
        font-size: 1.5em;
      }
    }
  }
}

.pipeline-selector {
  .more-menu {
    border-radius: 0px 5px 5px 0px;
    border-left: 1px dashed #d9d9d9;
  }
  .main-button {
    border-radius: 5px 0px 0px 5px;
    border-right: 0px;
  }
}

.dnd-item {
  left: auto !important;
  top: auto !important;
  .move-icon {
    opacity: 0;
  }
  &:hover {
    .move-icon {
      opacity: 1;
    }
  }
}

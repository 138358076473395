.task-view-container {
  .action-button {
    background-color: #f4f4f496;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
  }

  .ant-picker {
    border: 0;
  }

  .avatar-container {
    position: relative;
    .overlay {
      background: #00000075;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      cursor: pointer;
      color: #fff;
      z-index: 9;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      font-size: 1.2em;
      font-weight: bold;
      opacity: 0;
    }
    &:hover {
      .overlay {
        opacity: 1;
      }
    }
  }

  .task-view-input {
    box-shadow: 0px -4px 15px -8px #0000003b;
    padding-bottom: 8px;
  }

  // Patch for rare layout issue with tag input container
  .ant-select-selection-overflow {
    flex-wrap: nowrap !important;
  }
}

.mode-dark {
  .task-view-container {
    .action-bar {
      .hover-panel {
        background: #141b25;
      }
    }
    .action-button {
      background-color: #ffffff0f;
    }
  }
}

.task-actions-drop-down {
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    padding: 0px;
  }
  .action-button {
    text-align: left;
  }
}

@media (max-width: 1024px) {
  .responsive-modal {
    top: 65px !important;
    width: 100% !important;
    height: calc(100% - 65px) !important;
    max-width: calc(100% - 0px) !important;
    padding: 0px;
    &.very-big-modal {
      width: 100% !important;
    }
  }
}

.very-big-modal {
  top: 50px;
  width: 90% !important;
  max-width: calc(100% - 32px);
  height: calc(100% - 75px);
  .ant-modal-content {
    height: 100%;
    //overflow: auto;
    .ant-modal-body {
      height: calc(90vh - 100px);
      height: calc(100% - 58px);
      padding-bottom: 0px;
      > div {
        height: 100%;
        .container {
          overflow: auto;
          max-width: 100% !important;
        }
      }
    }
  }
}

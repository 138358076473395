.react-flow__node.selected {
  box-shadow: 0 0 10px #1a73e878;
  border-radius: 10px;
  .node-container {
    --tw-border-opacity: 1;
    border-color: rgba(49, 130, 206, var(--tw-border-opacity));
  }
}

.react-flow__edge.selected {
  .react-flow__edge-path {
    stroke-width: 5;
  }
}

.react-flow__edge-path {
  stroke-width: 3;
  cursor: pointer;
  &:hover {
    stroke-width: 5;
  }
}

.flow-delete-button {
  display: none !important;
}

.react-flow__edge:hover {
  .flow-delete-button {
    display: inline-flex !important;
  }
}

.react-flow__attribution {
  // opacity: 0;
  zoom: 0.0001;
}

.mode-dark {
  .message-node-container,
  .action-node-container,
  .condition-node-container,
  .gpt-assistant-node-container {
    color: #ffffff77;
    background: #000000;
    box-shadow: 0 0 10px #1a73e878;
  }

  .chat-flow-handle.react-flow__handle .chat-handle-label {
    background: #000000;
    box-shadow: 0 0 10px #1a73e878;
  }

  .bot-builder-sidebar {
    background: transparent;
    border-left: 1px solid #1a73e878;
  }
}

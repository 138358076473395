.chat-footer {
  margin: 15px 15px 0 15px;
  box-shadow: 0 -5px 10px 0px #00000000;
  border-radius: 10px;
  border: 1px solid transparent;
  transition: all;
  transition-duration: 400ms;
  &:focus-within {
    border: 1px solid #ebebeb;
    box-shadow: 0 -10px 10px 5px #00000017;
    .chat-input-email-data-editor {
      margin-top: -3px;
      top: 3px;
    }
  }

  .chat-input-email-data-editor {
    border: 1px solid #efefef;
    border-radius: 10px 10px 0 0;
    border-bottom: 0px;
    top: 3px;
    position: relative;
    z-index: 2;
    background: #fff;
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: none;
    }
  }
}

.mode-dark {
  .chat-footer {
    &:focus-within {
      border: 1px solid #ffffff2e;
      box-shadow: 0 -10px 10px 5px #ffffff17;
    }
    .chat-input-email-data-editor {
      background: #1a202d;
      border-color: rgba(45, 55, 72, var(--tw-border-opacity)) !important;
    }
  }
}

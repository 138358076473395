.high-chart-container {
  position: relative;
  .menu-background {
    display: block;
    width: 100%;
    height: 100%;
    // position: fixed;
    position: absolute;
    background: rgba(0, 0, 0, 0);
    top: 0px;
    left: 0px;
    border-radius: 10px;
    z-index: 9;
    .menu-container {
      border: 1px solid rgb(244, 244, 244);
      position: relative;
      background: #fff;
      border-radius: 10px;
      overflow: hidden;
      width: 300px;
    }
  }
}

.configuration-logo-upload {
  .edit-user-avatar {
    display: flex;
    align-items: center;
    &:hover {
      .upload-icon {
        border-radius: 10%;
      }
    }
  }
}

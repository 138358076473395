.home-page {
  background: #f4f4f4;

  .cards-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 40px;

    .get-started-card {
      flex: 0 0 auto;
      width: 320px;
      height: 100px;
      background: #fff;
      box-shadow: 0 0 10px #ececec;
      margin-right: 20px;
      border-radius: 10px;
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      .text {
        font-size: 1.5em;
        font-weight: bold;
        padding-left: 20px;
      }

      img {
        height: 170px;
      }
      &.disabled::before {
        content: " ";
        background: #ffffffaa;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 9;
        cursor: not-allowed;
        position: absolute;
        border-radius: 10px;
      }
    }

    .empty-card {
      flex: 0 0 auto;
      width: 200px;
      height: 100px;
    }
  }

  .video-card {
    height: 160px;
    border-radius: 6px;
    box-shadow: 3px 3px 10px #0000004d;
    cursor: pointer;
    transition: all 0.1s linear;
    translate: 0px 0px;
    &:hover {
      translate: -5px -5px;
      box-shadow: 3px 3px 10px #00000070;
    }
  }
}

.mode-dark {
  .bg-gray {
    background: #181e27;
  }
  .cards-container {
    .get-started-card {
      background-color: #3d4d65;
      box-shadow: 0 0 10px #00000040;
      &.disabled::before {
        background: #03030354;
      }
    }
  }
}

.chat-list-item {
  &:focus {
    background-color: #ebf4ff;
  }
  .pin-container {
    position: absolute;
    right: 0;
    top: 0px;
    .pinned,
    .pin {
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .pinned {
      background: #ff9800;
      color: #fff;
    }
    .pin {
      opacity: 0;
    }
  }
  &:hover {
    .pin-container {
      .pin {
        opacity: 1;
      }
    }
  }
  .name-line,
  .subject-line,
  .preview-line {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .text-container {
    width: 100%;
    overflow: hidden;
    .date {
      min-width: 52px;
    }
  }
  &:hover {
    // .subject-line {
    //   color: #1a73e8;
    // }
  }
  &.active {
    background-color: #ebf4ff;
    .name-line,
    .subject-line {
      font-weight: bold;
    }
    .subject-line {
      color: #1a73e8;
    }
  }
  .un-read-badge {
    position: absolute !important;
    left: -12px;
    z-index: 9;
    .ant-badge-status-dot {
      // width: 14px;
      // height: 14px;
      // width: 8px;
    }
  }
  &.has-unread {
    border-left: 4px solid #1990ff;
    .name-line,
    .subject-line {
      font-weight: bold;
    }
    .subject-line {
      color: #1a73e8;
    }
  }

  .avatar-container {
    .checkbox-container {
      opacity: 0;
    }
  }
  &:hover {
    .avatar-container {
      .checkbox-container {
        opacity: 1;
      }
    }
  }

  .connection-name-container {
    position: absolute;
    bottom: 14px;
    right: 34px;
    direction: unset;
    transform: scale(0.75);
    .ant-tag {
      background: #fff;
    }
  }
}

.mode-dark {
  .chat-list-item {
    &:focus {
      background: #154c8833;
    }

    &.active {
      background: #154c8833;

      .subject-line {
        color: #1a73e8;
      }
    }
    .connection-name-container {
      .ant-tag {
        background-color: black;
      }
    }
  }
}

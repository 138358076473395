.preset-editor-modal {
  .drop-indicator {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #ffffff77;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2em;
    z-index: 9;
  }
}

.message-row {
  .read-indicator {
    position: relative;
    top: -7px;
  }
  overflow-wrap: anywhere;

  .message-item-menu {
    top: 0px;
    opacity: 0;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.me {
    .message-item-menu {
      left: -30px;
    }
  }
  &.contact {
    .message-item-menu {
      right: -30px;
    }
  }
  &:hover {
    .message-item-menu {
      opacity: 1;
    }
  }
  .wmde-markdown.wmde-markdown-color {
    background: transparent;
  }
}

.rich-message-item {
  // background: #fff;
  border: 1px solid #ececec !important;
  border-radius: 5px;
  margin-bottom: 20px;
  line-break: anywhere;
  .ant-collapse > .ant-collapse-item {
    border-bottom: none;
  }
}

.mode-dark {
  .rich-message-item {
    border: 1px solid #ffffff24 !important;
  }
}

.ant-modal-root .ant-modal-content,
.dark-gradient-bg,
.ant-popover-content {
  // box-shadow: 0 0 30px #ffffff47;
  box-shadow: 5px 5px 45px #00000033, -9px 5px 45px #9688ff33,
    -7px -5px 14px #ff858533, 12px -4px 15px #80ff8433, 12px 10px 14px #888eff33,
    -10px 10px 45px #61c8ff33, -10px -7px 57px #00000033 !important;
}

.ant-modal-content {
  .ant-modal-header {
    border-bottom: 1px solid #f0f0f017;
  }

  .ant-modal-footer {
    border-top: 1px solid #f0f0f017;
  }
}

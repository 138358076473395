:root {
  --background: rgb(252 252 252);
  --a1: rgba(0 0 0 / 0.05);
  --a2: rgba(0 0 0 / 0.1);
  --foreground: rgb(28 28 29);
  --shadow: 0px 6px 20px rgb(0 0 0 / 20%);

  --unit: 8px;
}

html[data-theme-dark]:root {
  --background: rgb(28 28 29);
  --a1: rgb(53 53 54);
  --a2: rgba(255 255 255 / 0.1);
  --foreground: rgba(252 252 252 / 0.9);
  --shadow: rgb(0 0 0 / 50%) 0px 16px 70px;
}

.kb-mask {
  background: #00000063;
  z-index: 99999;
}

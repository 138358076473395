.side-bar-menu {
  &.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #1a73e81c;
  }

  .ant-menu-item {
    border-radius: 10px;
  }
  &.ant-menu-inline .ant-menu-item::after {
    border: 0;
  }

  .ant-menu-item,
  .ant-menu-submenu-title {
    font-weight: bold;
    margin-right: 12px;
  }
  .ant-menu-item:hover,
  .ant-menu-submenu-title:hover {
    background-color: #1a73e81c;
  }
}

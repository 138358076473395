.attachments-outer {
  .drop-indicator {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #ffffffb8;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    z-index: 9;
  }
}

.mode-dark {
  .attachments-outer {
    .drop-indicator {
      background: #000000b8;
    }
  }
}

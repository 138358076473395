.pricing-plans-container {
  .ant-row {
    justify-content: center;
  }
  .ant-tabs {
    &-nav {
      display: flex;

      .ant-tabs-tab {
        flex-grow: 1;
        margin-right: 0px;
        width: 100%;
        text-align: center;
      }
    }

    .ant-tabs-nav,
    .ant-tabs > div > .ant-tabs-nav {
      width: 100%;
    }

    .ant-tabs-nav .ant-tabs-nav-list,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
      width: 100%;
      .ant-tabs-tab-btn {
        width: 100%;
      }
    }
  }
}

.text-primary {
  color: #1a73e8;
}

.text-ellipsis {
  text-overflow: ellipsis !important;
}

.bg-gray {
  background: #f4f4f4;
}

.z-9999 {
  z-index: 9999;
}

.beautiful-shadow {
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
}

.disabled-section {
  opacity: 0.4;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: -2.5%;
    // background: #ffffff96;
    cursor: not-allowed;
    width: 105%;
    height: 100%;
    border-radius: 10px;
  }
}

.boxed-section {
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  margin: 20px;
  padding: 15px;
  background: #fff;
}

.container {
  flex-grow: 1;
  width: auto;
}

.full-w-label {
  label {
    width: 100%;
  }
}

.icon-fix {
  vertical-align: 0.2em;
}

.bold-form-labels {
  .ant-form-item-label > label {
    font-weight: bold;
  }
}

.fill-spinner {
  .ant-spin-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  > div {
    margin-bottom: 5px;
  }
}

.group {
  &:hover,
  &:active {
    .group-hover\:show {
      display: block !important;
    }
    .group-hover\:show-inline {
      display: inline-block !important;
    }
  }
}

.group {
  .item-visible-on-hover {
    opacity: 0;
  }
  &:hover,
  &:active {
    .item-visible-on-hover {
      opacity: 1;
    }
  }
}

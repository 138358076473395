.chat-flow-handle.react-flow__handle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ccc;
  border: 2px solid white;
  cursor: pointer;
  transition: all 0.2 linear;
  .add-button {
    opacity: 0;
    right: 2px;
    position: relative;
    font-size: 1.3em;
    top: -4px;
    background: #000000;
    color: #fff;
    border: 2px solid #fff;
    border-radius: 100%;
    width: 25px;
    height: 25px;
    cursor: pointer;
    i {
      top: -3px;
      position: relative;
      left: 1.6px;
    }
    &.pos-right {
      right: -45px;
    }
    &.pos-left {
      right: 25px;
    }
    &.pos-bottom {
      right: 5px;
      top: 25px;
    }
  }
  &:hover {
    .add-button {
      opacity: 1;
    }
  }

  &.condition-yes {
    position: absolute;
    left: 40%;
    bottom: 0px;
  }
  &.condition-no {
    position: absolute;
    left: 60%;
    bottom: 0px;
  }

  .chat-handle-label {
    position: absolute;
    background: #f7f7f7;
    padding: 5px;
    border-radius: 5px;
    font-size: 10px;
    font-weight: bold;
    &.default {
      bottom: -30px;
      left: -15px;
    }
    &.yes {
      bottom: -30px;
      left: -10px;
    }
    &.no {
      bottom: -30px;
      left: -7px;
    }
  }
}

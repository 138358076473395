.editable-text {
  &._4GdcU {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: background 0.2s ease;
    // min-height: 28px;
  }

  &._4GdcU:hover {
    cursor: pointer;
    background: rgba(220, 220, 220, 0.4);
  }

  &._i5--j {
    overflow-y: auto;
    transition: background 0.2s ease;
  }

  /* Width */
  &._i5--j::-webkit-scrollbar {
    width: 8px;
  }
  /* Track */
  &._i5--j::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  &._i5--j::-webkit-scrollbar-thumb {
    background: #ccc;
  }
  /* Handle on hover */
  &._i5--j::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
  /* Handle on drag */
  &._i5--j::-webkit-scrollbar-thumb:active {
    background: #666;
  }

  &._i5--j:hover {
    cursor: pointer;
    background: rgba(220, 220, 220, 0.4);
  }

  &._i5--j {
    width: 100%;
    padding: 5px;
    display: block;
    margin: 3px 0 3px 0;
    scrollbar-width: thin;
  }

  /* Width */
  &._i5--j::-webkit-scrollbar {
    width: 8px;
  }
  /* Track */
  &._i5--j::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  &._i5--j::-webkit-scrollbar-thumb {
    background: #ccc;
  }
  /* Handle on hover */
  &._i5--j::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
  /* Handle on drag */
  &._i5--j::-webkit-scrollbar-thumb:active {
    background: #666;
  }

  &._3qwMT {
    color: #999;
  }

  &._3F-ST {
    display: inline !important;
  }

  &._38077 {
    cursor: auto !important;
  }

  &._38077:hover {
    cursor: auto !important;
  }
}

.mode-dark {
  textarea.editable-text,
  input.editable-text {
    &._1EEDX {
      background: #000 !important;
    }
    background: #ffffff0a;
  }
  .editable-text {
    &._4GdcU:hover {
      background: rgba(220, 220, 220, 0.1);
    }
    &._i5--j:hover {
      background: rgba(220, 220, 220, 0.1);
    }
  }
}

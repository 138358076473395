.collection-item {
  .move-icon {
    opacity: 0;
  }
  &:hover {
    .move-icon {
      opacity: 1;
    }
  }
  .ant-collapse-content {
    border-top: none;
  }
  .ant-collapse > .ant-collapse-item {
    border-bottom: none;
  }
  .ant-collapse-header {
    background: none;
  }

  textarea {
    outline: #438bd1 2px solid;
    padding: 5px;
    border-radius: 5px;
    &._1EEDX {
      outline: #438bd1 2px solid;
    }
  }
}

.draggable-collection {
  outline: none;
}

.about-workspace {
  .list-details {
    margin-bottom: 5px;
    border-radius: 5px;
    padding: 10px;

    &.editable-field {
      cursor: pointer;
      .edit-icon {
        visibility: hidden !important;
      }
      &:hover {
        .edit-icon {
          visibility: visible !important;
        }
      }
    }
  }
}

.color-input-popover {
  .ant-popover-inner-content {
    padding: 0;
  }
  .github-picker {
    border: none !important;
  }
}
.color-picker-selected-color {
  width: 25px;
  height: 25px;
}

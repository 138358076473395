body,
html {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

html {
  font-size: 15px;
  &.mode-dark {
    background-color: #1a202c !important;
    transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
    body {
      background-color: #1a202c !important;
      transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
    }
  }
}

body {
  overscroll-behavior-y: none;
  overflow-x: hidden;
}

body *:not(input):focus {
  outline: none;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #0000001c;
  border-radius: 20px;
  border: 0px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #00000030;
}

/* Fix for perfect scrollbar appearing on left instead of normal right position */
.ps__rail-y {
  right: 0 !important;
  left: unset !important;
}
.ps__rail-x {
  top: unset !important;
  bottom: 0 !important;
}

// Fix for Scrollbar Taking extra width
.ReactVirtualized__Grid.ReactVirtualized__List {
  overflow: overlay !important;
}

@media (max-width: 1550px) {
  html,
  .mode-dark {
    font-size: 12px;
  }
}

@media (max-width: 1450px) {
  html,
  .mode-dark {
    font-size: 11.5px;
  }
}

@media (max-width: 1300px) {
  html,
  .mode-dark {
    font-size: 11px;
  }
}

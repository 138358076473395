.mode_transition {
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
}
.ant-layout-sider {
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
}
.ant-menu {
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
}
.ant-layout-sider-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  // background-color: rgba(0, 0, 0, 0.06) !important;
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
  button {
    transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
  }
}
.ant-menu-item-selected {
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
}
.ant-btn {
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
}
.ant-radio-button-wrapper {
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
}
.ant-input {
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
}
.ant-input-group-addon {
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
}
.filter-button {
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
}
.ant-notification-notice {
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
}
.ant-notification-notice-message {
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
}
.ant-notification-close-icon {
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
}
.ant-result-title {
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
}
.ant-divider,
.ant-scroll-number {
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
}

.ant-tabs-nav::before {
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
}
.ant-select-selector {
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
}
.ant-select-selection-placeholder {
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
}
.ant-select-selection-item,
.ant-select-selection-item-remove {
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
}
.ant-tag {
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
}
.ant-menu-submenu-arrow {
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
}
.ant-card,
.ant-card-head,
.ant-card-actions,
.ant-card-actions > li,
.ant-card-meta-title,
.ant-card-meta-description {
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
}
.ant-comment-content-detail > div > p,
.ant-comment-content-author-name > a,
.ant-comment-content-author-time > span {
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
}
.ant-pagination-item,
.ant-pagination-item > a,
.ant-pagination-item-link,
.ant-pagination-item-ellipsis,
.ant-select-arrow {
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
}
.home-page > div {
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
}
.cards-container > div,
.cards-container > div::before {
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
}
.coming-soon,
.ant-picker-panel,
.ant-picker-content > thead > tr > th,
.ant-picker-content > tbody > tr > td {
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
}
.ant-result-subtitle {
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
}
.ant-table-cell {
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
}
.ant-tabs-tabpane > div {
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
}
.ant-tabs-tab-btn {
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
}
.reports-page {
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
}
.content-container {
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
}
.ant-menu-item-disabled > i,
.ant-menu-item-disabled > span {
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
}
.config-content {
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
}
.styled-menu-container {
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
}

.ant-dropdown-menu,
.ant-dropdown-menu-item-divider,
.ant-dropdown-menu-item,
.ant-dropdown-menu-item > a,
.ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu-expand-icon > span {
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
}
.user-avatar::before {
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
}

.draft-js-advanced {
  position: relative;

  h1 {
    font-size: 2em;
    font-weight: bold;
  }

  h2 {
    font-size: 1.7em;
    font-weight: bold;
  }

  h3 {
    font-size: 1.4em;
    font-weight: bold;
  }

  h4 {
    font-size: 1.3em;
    font-weight: bold;
  }

  h5 {
    font-size: 1em;
    font-weight: bold;
  }

  h6 {
    font-size: 1em;
    font-weight: 600;
  }

  p {
    margin-top: 0.2em;
    margin-bottom: 0.2em;
  }

  .bf-container {
    overflow-y: hidden;
    overflow-x: hidden;
    width: 100%;
    display: flex;
    // flex-direction: column;
    flex-direction: column-reverse;

    &.fullscreen {
      .bf-content {
        height: auto;
        max-height: 100%;
      }
    }
  }

  &.top-controls {
    .bf-container {
      flex-direction: column !important;
    }
    .bf-content {
      min-height: 170px;
    }
  }

  &.hide-controls {
    .bf-controlbar {
      display: none;
    }
  }

  .bf-controlbar {
    box-shadow: 0 0px 15px 7px rgb(0 0 0 / 6%);
  }

  .bf-content {
    // height: 205px;
    height: auto;
    max-height: 300px;
    flex: 1;
  }

  // Fix for Grammary
  .bf-container .DraftEditor-editorContainer {
    width: 98%;
    overflow: hidden;
    .public-DraftEditor-content {
      word-break: break-word;
    }
  }

  .public-DraftStyleDefault-block,
  .public-DraftEditorPlaceholder-inner {
    margin: 0.5em 0;
  }

  .variable-token-item {
    box-sizing: border-box;
    position: relative;
    top: -2px;
    padding: 2px 6px;
    background-color: #fff;
    border: 1px solid #bbb;
    border-radius: 2px;
    background-image: linear-gradient(#fff, #f8f8f8);
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
    color: #666;
    font-size: 12px;
    cursor: default;
    &:hover {
      border-color: #999;
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
    }
  }

  [class*="draftJsMentionPlugin__mention__"] {
    color: #4a85bb;
    text-decoration: none;
  }

  [class*="draftJsMentionPlugin__mentionSuggestions__"] {
    border: 1px solid #eee;
    background: #fff;
    border-radius: 2px;
    cursor: pointer;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    transform-origin: 50% 0%;
    transform: scaleY(0);
    position: absolute;
    z-index: 999;
    width: 100%;
    max-width: 400px;
  }

  .fixed-top {
    left: 0px !important;
    top: auto !important;
    max-width: none;
    bottom: calc(100% + 0px);
    transform-origin: bottom !important;
  }

  [class*="draftJsMentionPlugin__mentionSuggestionsEntryContainer__"] {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  [class*="draftJsMentionPlugin__mentionSuggestionsEntryContainerLeft__"],
  [class*="draftJsMentionPlugin__mentionSuggestionsEntryContainerRight__"] {
    // display: table-cell;
    // vertical-align: middle;
  }

  [class*="draftJsMentionPlugin__mentionSuggestionsEntryContainerRight__"] {
    width: 100%;
    padding-left: 8px;
  }

  [class*="draftJsMentionPlugin__mentionSuggestionsEntry__"] {
    padding: 7px 10px 3px 10px;
    transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
  }

  [class*="draftJsMentionPlugin__mentionSuggestionsEntry__"]:active {
    background-color: #cce7ff;
  }

  [class*="draftJsMentionPlugin__mentionSuggestionsEntryFocused__"] {
    composes: mentionSuggestionsEntry;
    background-color: #e6f3ff;
  }

  [class*="draftJsMentionPlugin__mentionSuggestionsEntryText__"],
  [class*="draftJsMentionPlugin__mentionSuggestionsEntryTitle"] {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  [class*="draftJsMentionPlugin__mentionSuggestionsEntryText__"] {
  }

  [class*="draftJsMentionPlugin__mentionSuggestionsEntryTitle__"] {
    font-size: 80%;
    color: #a7a7a7;
  }

  [class*="draftJsMentionPlugin__mentionSuggestionsEntryAvatar__"] {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  .editor :global(.public-DraftEditor-content) {
    min-height: 140px;
  }
}

.mode-dark {
  .draft-js-advanced {
    [class*="draftJsMentionPlugin__mentionSuggestions__"] {
      border: 1px solid rgba(255, 255, 255, 0.17);
      background: #000;
    }

    [class*="draftJsMentionPlugin__mentionSuggestionsEntryFocused__"] {
      background-color: #ffffff1d;
    }

    [class*="draftJsMentionPlugin__mentionSuggestionsEntryTitle__"] {
      color: #ffffff;
    }
  }
}

.bf-modal-body {
  overflow: inherit;
}

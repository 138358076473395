.help-center {
  .ant-modal-content {
    height: calc(100vh - 50px);
  }

  .help-center-banner {
    height: 200px;
    background-position: right;
    background-size: auto 145%;
    background-repeat: no-repeat;
  }

  .help-center-collection-banner {
    height: 200px;
    background-position: right;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .search-container {
    border: 1px solid #dedede;
    padding: 20px;
    // box-shadow: 0 0 5px #00000021;
    border-radius: 10px;
    margin: 20px;
    margin-top: -44px;
  }
}

.mode-dark {
  .help-center {
    .ant-modal-header,
    .ant-modal-body {
      background: #12161e;
    }
  }
}

.image-uploader-with-input {
  width: 100%;
  height: 100%;
  position: relative;
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 9;
  }
  .upload-icon {
    display: none;
  }
  &:hover {
    .upload-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: #00000065;
      // border-radius: 50%;
      color: #fff;
      cursor: pointer;
      z-index: 8;
    }
  }
}

.chat-messages {
  position: relative;
  // padding: 16px 0 40px 40px;
  padding: 16px 8px 40px 40px;

  .rich-message-item {
    .attachments-cont {
      align-items: flex-start;
    }
  }

  .message-row {
    &.contact {
      .bubble {
        color: rgba(0, 0, 0, 0.9);
        background-color: #f1f0f0;

        // a {
        //   color: rgba(0, 0, 0, 0.9);
        // }
      }
    }

    &.me {
      .bubble {
        background-color: #1a73e8;
        color: #fff;

        a {
          color: #fff;
        }
      }
    }

    &.campaign {
      .bubble {
        background-color: #1a73e8;
        color: #fff;

        a {
          color: #fff;
        }
      }
    }
  }

  .message-row {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 16px 4px 16px;

    .avatar {
      position: absolute;
      left: -32px;
      margin: 0;
    }

    .bubble {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px;
      max-width: 100%;
      transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;

      .message {
        white-space: pre-wrap;
        line-height: 1.2;
        max-width: 500px;
        overflow-wrap: break-word;
        > a {
          overflow-wrap: anywhere;
        }
        > a:hover {
          text-decoration: underline;
        }
      }
    }

    .time {
      position: absolute;
      display: none;
      width: 100%;
      font-size: 11px;
      margin-top: 2px;
      color: #666;
      top: 100%;
      left: 0;
      white-space: nowrap;
    }

    .email-actions {
      margin-top: 15px;
      padding-top: 15px;
    }

    &.contact {
      .bubble {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;

        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;

        .time {
          margin-left: 12px;
        }
      }

      &.first-of-group {
        .bubble {
          border-top-left-radius: 20px;
        }
      }

      &.last-of-group {
        .bubble {
          border-bottom-left-radius: 20px;
        }
      }
      .email-actions {
        border-top: 1px dashed #444;
      }
    }

    &.me {
      padding-left: 40px;

      .avatar {
        order: 2;
        margin: 0 0 0 16px;
      }

      .attachments-cont {
        margin-left: auto;
      }

      .bubble {
        margin-left: auto;

        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;

        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;

        .time {
          justify-content: flex-end;
          right: 0;
          margin-right: 12px;
        }
      }

      &.first-of-group {
        .bubble {
          border-top-right-radius: 20px;
        }
      }

      &.last-of-group {
        .bubble {
          border-bottom-right-radius: 20px;
        }
      }

      .email-actions {
        border-top: 1px dashed #fff;
      }
    }

    &.campaign {
      padding-left: 40px;

      .avatar {
        order: 2;
        margin: 0 0 0 16px;
      }

      .attachments-cont {
        margin-left: auto;
      }

      .bubble {
        margin-left: auto;

        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;

        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;

        .time {
          justify-content: flex-end;
          right: 0;
          margin-right: 12px;
        }
      }

      &.first-of-group {
        .bubble {
          border-top-right-radius: 20px;
        }
      }

      &.last-of-group {
        .bubble {
          border-bottom-right-radius: 20px;
        }
      }
    }

    &.contact + .me,
    &.me + .contact {
      padding-top: 20px;
      margin-top: 20px;
    }

    &.first-of-group {
      .bubble {
        border-top-left-radius: 20px;
        padding-top: 13px;
      }
    }

    &.last-of-group {
      .bubble {
        border-bottom-left-radius: 20px;
        padding-bottom: 13px;
      }
      .time {
        display: flex;
      }
    }

    &.system {
      text-align: center;
      align-items: center;
      justify-content: center;
      // border: 1px solid #efeded;
      // background: #f4f4f4;
      margin-right: 40px;
      margin-bottom: 25px;
      border-radius: 35px;
      padding: 0px;
      margin-top: 20px;
      color: #666;
      font-size: 0.8em;

      .bubble {
        padding: 10px;
        .message {
          > a:hover {
            text-decoration: none;
          }
        }

        .time {
          text-align: center;
          justify-content: center;
          align-items: center;
          margin-top: -7px;
        }
      }
    }
  }
  // Attachments - Start
  .attachments-cont {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 5px;
    flex-direction: column;
    padding-top: 12px;
    border: 1px solid #ececec;
    box-shadow: 2px 3px 10px #0000000f;
    margin-top: 9px;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 20px;

    .attachment {
      max-width: 600px;
      min-width: 100px;
      margin: 4px;
      .attachment-inner-cont {
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        overflow: hidden;
        padding: 10px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;

        &.image {
          max-width: 200px;
        }

        &.link-system {
          flex-direction: column;
          flex-direction: row;
          font-size: 0.8em;
          // border: 0px solid #e9e9e9;
          margin-bottom: -6px;
          border-radius: 25px;
          transform: scale(1);
          transition: all 0.2s linear;

          .text {
            &:hover {
              text-decoration: underline;
            }
          }

          .mat-caption {
            padding-left: 10px;

            &:hover {
              text-decoration: underline;
            }
          }

          &:hover {
            transform: scale(1.1);
          }
        }

        &.link {
          flex-direction: column;
          justify-content: start;
          align-items: start;

          // border: 0px solid #e9e9e9;
          .text {
            &:hover {
              text-decoration: underline;
            }
          }

          .mat-caption {
            &:hover {
              text-decoration: underline;
            }
          }

          .image {
            max-width: 400px;
          }

          // .more-links {
          // }
        }
      }
    }

    .title {
      display: block;
      margin-top: 0px;
    }
  }
  .file-attachments {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .attachment {
      min-width: 130px;
      height: 130px;
      margin-right: 10px;
      border: 1px solid #eaeaea;
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer;
      .hover {
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 99;
        justify-content: center;
        align-items: center;
        background: #ffffffd1;
      }
      &:hover {
        .hover {
          display: flex;
        }
      }
    }
  }
  // Attachments- End
}

// .ant-collapse-header {
//   background-image: linear-gradient(0deg, transparent, #f4f4f4);
// }

.new-message-notification {
  background: #757575;
  color: #fff;
  padding: 5px;
  border-radius: 20px;
  position: absolute;
  bottom: 5px;
  width: 220px;
  text-align: center;
  left: calc(50% - 110px);
}

.folders {
  font-weight: bold;
  padding-right: 10px;
  border-right: 0px;

  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: 0px;
  }

  .ant-menu-item.ant-menu-item-selected {
    border-radius: 10px;
  }

  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    border-right: 0px;
  }
}
/* NOTE: edited by Ruby*/
.mode-dark {
  .chat-messages {
    .message-row {
      &.contact {
        .bubble {
          color: #fff;
          background: rgba(226, 232, 240, 0.1) !important;
          a {
            color: #fff;
          }
        }
        .email-actions {
          border-top: 1px dashed #fff;
        }
      }
      &.me {
        .bubble {
          background-color: #244e85;
          color: #fff;

          a {
            color: #fff;
          }
        }
        .email-actions {
          border-top: 1px dashed #fff;
        }
      }

      &.system {
        .bubble {
          color: #bbb;

          a {
            color: #bbb;
          }
        }
      }

      .attachments-cont {
        border: 1px solid #263346;
        box-shadow: 2px 3px 10px #0000000f;
      }

      .file-attachments {
        .attachment {
          border: 1px solid #263346;
        }
      }
    }
  }
}
/* NOTE: !edited by Ruby*/

html,
.mode-dark {
  .main-navigation-bar {
    .ant-menu-inline-collapsed {
      .ant-menu-title-content {
        width: 10px;
      }
    }

    .phone-ringing-state {
      animation: phone-ringing-animation 1s infinite ease-in-out;
    }
    @keyframes phone-ringing-animation {
      0% {
        transform: rotate(0) scale(1) skew(1deg);
      }
      10% {
        transform: rotate(-25deg) scale(1) skew(1deg);
      }
      20% {
        transform: rotate(25deg) scale(1) skew(1deg);
      }
      30% {
        transform: rotate(-25deg) scale(1) skew(1deg);
      }
      40% {
        transform: rotate(25deg) scale(1) skew(1deg);
      }
      50% {
        transform: rotate(0) scale(1) skew(1deg);
      }
      100% {
        transform: rotate(0) scale(1) skew(1deg);
      }
    }

    .ant-menu-root {
      font-size: 1.3rem;
      padding: 0px 0.3rem;
    }
    .ant-menu-vertical,
    .ant-menu-inline {
      border-right: 0px !important;
      .ant-menu-item {
        height: 3.5rem;
        line-height: 3.5rem;
        margin-top: 1rem;
        border-radius: 10px;

        .ant-menu-item-icon {
          width: 3.5rem;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
        i {
          font-size: 1.8rem !important;
          line-height: 3.5rem !important;
          position: relative !important;
          // margin-right: 0.7rem !important;
        }
        &.ant-menu-item-active {
          background: #0000000f;
        }
      }
      .ant-menu-item::after {
        border-right: 0px !important;
      }
    }

    .ant-menu-inline-collapsed {
      border-right: 0px;
      .ant-menu-item {
        padding: 0px;
        i {
          position: relative;
          // left: -0.3rem;
        }
      }
    }

    .ant-badge-count {
      min-width: 17px !important;
      height: 17px !important;
      line-height: 17px !important;
    }
  }
}

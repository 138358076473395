.chat-widget-install-instructions {
  .plugin-platform-logo {
    width: 30px;
    height: 30px;
    cursor: not-allowed;
  }
  .row-form-container,
  .data-collection-fields {
    .ant-form-item {
      margin-bottom: 0px;
    }
    .ant-form-item-control-input {
      min-height: 0px;
    }
  }
  .row-form-container {
    margin-bottom: 10px;
  }
}

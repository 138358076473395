.simple-table-style {
  border: solid 1px #4a4a4a;
  thead th {
    background-color: #2f2f2f;
    border: solid 1px #4a4a4a;
    color: #ffffff;
  }
  tbody td {
    border: solid 1px #4a4a4a;
    color: #fff;
  }
}

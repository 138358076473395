.keyboard-shortcut {
  // font-family: monospace;
  box-sizing: border-box;
  // margin-left: 5px;
  // color: rgba(56, 56, 56, 0.85);
  // font-variant: tabular-nums;
  list-style: none;
  // font-feature-settings: "tnum", "tnum", "tnum";
  display: inline-block;
  height: auto;
  // margin-right: 8px;
  padding: 0 2px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  // background: #fafafa;
  // border: 1px solid #d9d9d9;
  border-radius: 2px;
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
  // font-weight: bold;
}

.bot-builder-sidebar {
  max-width: 300px;
  width: 20%;
  background: #fcfcfc;
  border-left: 1px solid #eee;
  .description {
    margin-bottom: 10px;
  }
  .dndnode {
    border: 1px solid #1a192b;
    border-radius: 5px;
    cursor: grab;
    min-height: 40px;
    padding: 8px;
    display: flex;
    margin-bottom: 8px;
    font-size: 1.2em;
    &:hover {
      box-shadow: 0 0 5px #00000044;
    }
    i {
      margin-right: 4px;
    }
    &.input {
      border-color: #0041d0;
    }
    &.output {
      border-color: #ff0072;
    }
  }
}

.mode-dark {
  .bot-builder-sidebar {
    .dndnode {
      border: 1px solid #3d3d3d;
      &:hover {
        box-shadow: 0 0 5px #00000044;
      }
      i {
        margin-right: 4px;
      }
    }
  }
}

.task-card {
  .check-box {
    .icon {
      display: block;
    }
    .on-hover-icon {
      display: none;
    }
    &:hover {
      .icon {
        display: none;
      }
      .on-hover-icon {
        display: block;
      }
    }
  }
}

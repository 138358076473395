.contact-card {
  .edit-user-avatar {
    width: 80px;
    height: 80px;
    position: relative;
  }
  .info-section {
    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      cursor: pointer;
      min-width: 160px;
      &:hover {
        background: #f4f4f4;
      }
      .number {
        font-weight: bold;
        font-size: 1.5rem;
      }
    }
  }
  .conversation-list {
    max-height: 400px;
    overflow: auto;
    width: 400px;
  }
}

.simple-table-style {
  border: solid 1px #dde3ee;
  border-collapse: collapse;
  border-spacing: 0;
  font:
    normal 13px Arial,
    sans-serif;

  thead th {
    background-color: #dde3ee;
    border: solid 1px #dde3ee;
    color: #383838;
    padding: 10px;
    text-align: left;
    // text-shadow: 1px 1px 1px #fff;
  }
  tbody td {
    border: solid 1px #dde3ee;
    color: #333;
    padding: 10px;
    // text-shadow: 1px 1px 1px #fff;
  }
}

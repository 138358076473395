.activity-indicator-out-cont {
  .activity-indicator-cont {
    width: 100%;
    height: 20px;
    background: #f4f4f4;
    border-radius: 5px;
    display: flex;
    position: relative;
    overflow: hidden;

    .activity-slot {
      height: 100%;
      display: inline-block;
      position: absolute;
      border-radius: 5px;
      cursor: context-menu;
      opacity: 0.85;

      &:hover {
        opacity: 1;
      }
    }

    .current-time-indicator {
      width: 1px;
      height: 130%;
      top: -15%;
    }
  }

  .time-cont {
    .current-time {
      margin-left: -17px;
    }
  }
}

.manage-chat-bots {
  .ant-table-tbody > tr:hover > td {
    background: #f4f4f4 !important;
  }
  .full-w-menu-content {
    .ant-menu-title-content {
      flex: 1;
    }
  }
}

.mode-dark {
  #root {
    .manage-chat-bots {
      .ant-table-tbody > tr:hover > td {
        background: #020202 !important;
      }
    }
  }
}

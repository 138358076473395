li {
  list-style-type: none;
}

.ant-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  i {
    float: left;
    padding-right: 4px;
    line-height: 0.75rem;
  }
  &.ant-btn-icon-only {
    i {
      padding-right: 0px;
    }
  }
  span {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    i {
      float: left;
      padding-right: 4px;
      line-height: 0.75rem;
    }
    &.ant-btn-icon-only {
      i {
        padding-right: 0px;
      }
    }
  }
}

.ant-image-preview-img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-tabs,
.ant-tabs:focus,
.ant-spin-nested-loading,
.ant-spin-nested-loading:focus,
.ant-modal-close,
.ant-modal-close:focus,
.ReactVirtualized__Grid.ReactVirtualized__List,
.ReactVirtualized__Grid.ReactVirtualized__List:focus {
  outline: none;
}

.ant-table-sticky-holder {
  z-index: 7;
  width: 99%;
}

.ant-modal {
  top: auto;
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 24px;
}
.ant-modal-content {
  max-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .ant-modal-body {
    flex: 1;
    overflow: auto;
  }
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  flex-direction: row;
  display: flex;
  align-items: center;
}

.ant-avatar-image {
  background: transparent !important;
}

.ant-notification-notice.prompt-notification::after {
  content: "";
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
  background: #0000002e;
  z-index: -15;
}

.alert-notification-container::before {
  content: "";
  width: 100%;
  height: 100%;
  background: #00000045;
  position: fixed;
  left: 0;
  top: 0;
}

// .ant-table {
//   background: none !important;
// }
// .ant-table-tbody > tr.ant-table-row:hover > td {
//   background: rgba(226, 232, 240, 0.1) !important;
// }

.search-with-filter {
  .ant-input-group-addon {
    .ant-btn {
      border-radius: 0;
    }
  }
}

.ant-space {
  font-size: inherit;
}

.ant-tour {
  // min-width: 520px;
  width: auto;
  max-width: 800px;
  .ant-tour-inner {
    padding: 40px;
    .ant-tour-footer {
      margin-top: 30px;
      .ant-tour-indicators {
        .ant-tour-indicator {
          width: 12px;
          height: 12px;
        }
      }

      .ant-btn {
        font-size: 1.4rem;
        padding: 1.25rem 2rem;
      }
    }
  }
}

.ant-modal-content {
  padding: 0;
  .ant-modal-header {
    border-bottom: 1px solid #f0f0f0;
    padding: 1rem 1.4rem;
    margin-bottom: 0px;
  }

  .ant-modal-body {
    padding: 1rem 1.4rem;
  }

  .ant-modal-footer {
    border-top: 1px solid #f0f0f0;
    padding: 1rem 1.4rem;
    .ant-btn-primary {
      font-weight: bold;
    }
  }
}

.ant-menu.ant-menu-inline {
  border: none;
}

.ant-spin-nested-loading > div > .ant-spin {
  display: flex;
  justify-content: center;
  align-items: center;
}

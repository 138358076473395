.contact-header {
  height: 120px;
  background: linear-gradient(0deg, #f5f5f5, transparent);
  padding-top: 100px;
}

.mode-dark {
  .contact-header {
    background: linear-gradient(0deg, #02020263, transparent);
  }
}

.article-editor {
  .editor-shell {
    margin: 0px;
  }
}

.article-editor {
  .document-header {
    box-shadow: 0 0 17px #00000014;
    padding: 20px;
    margin-bottom: -25px;
    border-radius: 5px;
    // padding-top: 40px;
  }

  .document-container {
    border-radius: 10px;
    box-shadow: 0 0 17px #00000014;
    padding: 60px;
    min-height: 800px;
    width: 720px;
    z-index: 9;
    background: #fff;
  }

  .empty-form > .ant-form-item-control > .ant-form-item-control-input {
    min-height: 0;
  }
}

.mode-dark {
  .document-container {
    background: #000;
  }
}

.edit-contact-modal {
  .ant-collapse-header {
    font-weight: bold;
    border: 0;
    background-image: none;
  }
  .ant-collapse-content {
    border-top: none;
  }
  .ant-collapse > .ant-collapse-item {
    border-bottom: none;
  }
  .error-field {
    margin-bottom: 0px;
    .ant-form-item-control-input {
      min-height: 0;
    }
  }
}

.bg-gray {
  background: #181e27;
}

.dark\:invert {
  filter: invert(1);
}
.dark-gradient-bg-on-focus {
  &:focus-within {
    box-shadow: 5px 5px 45px #00000033, -9px 5px 45px #9688ff33,
      -7px -5px 14px #ff858533, 12px -4px 15px #80ff8433,
      12px 10px 14px #888eff33, -10px 10px 45px #61c8ff33,
      -10px -7px 57px #00000033 !important;
  }
}

.dark\:hover\:bg-gray-900:hover {
  background-color: #141414 !important;
}
.dark\:hover\:bg-gray-800 {
  &:hover {
    background-color: #2d3748 !important;
  }
}

.dark\:hover\:bg-gray-700 {
  &:hover {
    background-color: #425069 !important;
  }
}

.dark\:hover\:bg-orange-800 {
  &:hover {
    background-color: #9c4221 !important;
  }
}

.chat-view-container {
  .action-button {
    background-color: #f4f4f496;
  }
  // Remove Borders of Tag Input and other Inputs
  // .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  //   border: none;
  // }

  .avatar-container {
    position: relative;
    .overlay {
      background: #00000075;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      cursor: pointer;
      color: #fff;
      z-index: 9;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      font-size: 1.2em;
      font-weight: bold;
      opacity: 0;
    }
    &:hover {
      .overlay {
        opacity: 1;
      }
    }
  }

  .action-bar {
    position: relative;
    .hover-panel {
      position: absolute;
      width: 100%;
      background: #fff;
      max-height: 0px;
      min-height: 0px;
      top: 49px;
      left: 0px;
      z-index: -1;
      box-shadow: 0px 6px 10px #00000017;
      padding-left: 10px;
      padding-right: 10px;
      opacity: 0;
      transition: all 0.2s linear;
      padding-top: 5px;
      .action-button {
        margin-bottom: 10px;
        margin-right: 10px;
      }
    }
    &:hover {
      .hover-panel {
        max-height: 180px;
        min-height: 60px;
        z-index: 9;
        opacity: 1;
      }
    }
  }
  .chat-view-input {
    // box-shadow: 0px -4px 15px -8px #0000003b;
    padding-bottom: 8px;
    z-index: 9999;
  }

  // Patch for rare layout issue with tag input container
  .ant-select-selection-overflow {
    flex-wrap: nowrap !important;
  }
}

.mode-dark {
  .chat-view-container {
    .action-bar {
      .hover-panel {
        background: #141b25;
      }
    }
    .action-button {
      background-color: #ffffff0f;
    }
  }
}

.chat-actions-drop-down {
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    padding: 0px;
  }
  .action-button {
    text-align: left;
    justify-content: left !important;
  }
  a.ant-btn.action-button {
    padding: 1.2rem;
  }
}

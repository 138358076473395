.configure-chat-widget {
  .chat-widget-container {
    position: relative;
    &::before {
      content: "";
      background: linear-gradient(0deg, white, transparent);
      position: absolute;
      left: 0px;
      bottom: 0px;
      width: 100%;
      height: 30px;
      z-index: 99;
    }
  }
}

.mode-dark {
  .configure-chat-widget {
    .chat-widget-container {
      &::before {
        content: "";
        background: linear-gradient(0deg, #19202d, transparent);
      }
    }
  }
}

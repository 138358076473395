.image-selector-modal {
  .ant-tabs > .ant-tabs-nav,
  .ant-tabs > div > .ant-tabs-nav {
    margin-bottom: 0;
  }

  .file-attachments {
    display: flex;
    flex-direction: row;
    .attachment {
      min-width: 70px;
      height: 70px;
      margin-right: 10px;
      border: 1px solid #eaeaea;
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer;
      .ant-image-img {
        max-height: 50px;
        width: auto;
      }
      .hover {
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 99;
        justify-content: center;
        align-items: center;
        background: #ffffffd1;
      }
      &:hover {
        .hover {
          display: flex;
        }
      }
    }
  }
}

.file-list {
  .ant-image {
    width: 130px;
    margin: 5px;
    border-radius: 5px;
    overflow: hidden;
    padding: 10px;
    border: 1px solid #e8e8e8;
    img {
      border-radius: 5px;
    }
  }
}

.file-picker-menu-container {
  box-shadow: 0 0 10px #00000017;
  padding-top: 20px;
  padding-left: 20px;
  .ant-menu-item-selected {
    border-radius: 10px;
  }

  .ant-menu-item {
    font-weight: bold;
    margin-right: 12px;
  }
}

.image-selector-drag-and-drop-container {
  .ant-upload-list {
    padding: 20px;
  }
}

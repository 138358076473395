.single-editable-faq-collection {
  // border: 1px solid #f4f4f4 !important;
  .ant-collapse-header {
    padding: 0px !important;
  }
  .view-more-toggle-container {
    border: #e2e8f0 solid 1px;
    background: #fff;
  }
  .ant-collapse-content {
    border-top: 0;
  }

  .draft-js-wrapper {
    min-height: 300px;
    border: 1px solid #f4f4f4;
    margin-bottom: 20px;
    .DraftEditor-root {
      padding: 10px;
    }
  }
  .ant-collapse-header {
    background: none;
  }
}
.faq-add {
  .ant-collapse-item {
    border-bottom: none;
  }
  .ant-collapse-content {
    border: #e2e8f0 solid 1px;
  }

  &.ant-collapse > .ant-collapse-item {
    border-bottom: none;
  }
  .ant-collapse-header {
    background: none;
  }

  .draft-js-wrapper .draft-js-editor {
    min-height: 280px;
  }
}

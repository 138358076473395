.main-layout-outer-container {
  // The following adds a blue border on top
  // padding-top: 5px;
  // &::before {
  //   content: "";
  //   position: absolute;
  //   width: 94%;
  //   left: 3%;
  //   z-index: 999;
  //   border-bottom: 10px solid #0466ff;
  //   top: -5px;
  //   border-radius: 50px;
  // }
  .main-layout-container {
    overflow-x: hidden;
  }
}

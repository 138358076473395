.simple-pivot-table-container {
  .reactPivot {
    margin-top: 0px;
    box-shadow: none;
    height: 100%;
  }
  .reactPivot-soloDisplay {
    width: 200px;
    border-radius: 10px;
    border: 1px solid #ccc;
  }

  .reactPivot select {
    height: 28px;
    margin-right: 5px !important;
    margin-top: 5px !important;
    background-color: transparent;
    border: 1px solid #1616161c;
    border-radius: 5px;
  }
  .reactPivot-csvExport button {
    height: 28px;
    margin-right: 5px !important;
    margin-top: 5px !important;
    background-color: transparent;
    border: 1px solid #1616161c;
  }
}

.mode-dark {
  .simple-pivot-table-container {
    .reactPivot {
      background: transparent;
    }
    .reactPivot select,
    .reactPivot-csvExport button {
      color: #fff;
      border: 1px solid #ffffff21;
    }
    .reactPivot-results tr:hover td {
      background: #f5f5f522;
    }
  }
}

.chat-search-input {
  .filter-button {
    height: 2.5rem;
    width: 40px;
    background: #f4f4f4;
    border-radius: 0 5px 5px 0;
    margin-left: -2px;
    border: 1px solid #d9d9d9;
    border-left: 0px;
  }
  .ant-input-group .ant-input {
    height: 2.5rem;
  }
  .ant-btn {
    height: 2.5rem;
  }
}

.mode-dark {
  .chat-search-input {
    .filter-button {
      background: #0000001c;
      border: 1px solid #ffffff1c;
      border-left: 0px;
    }
  }
}

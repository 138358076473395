.pipe-line-boxes {
  .pipe-line-box:first-child {
    margin-left: -15px;
  }
  &.stages-5 {
    .arrow-box {
      width: 100px;
      height: 100px;
      right: -15px;
      top: -29px;
    }
  }
  &.stages-6 {
    .arrow-box {
      width: 70px;
      height: 70px;
      right: -15px;
      top: -16px;
    }
  }
  i {
    position: absolute;
    left: 50%;
    font-size: 1.5em;
    top: 10%;
    z-index: 9;
  }
  .arrow-box {
    position: relative;
    float: right;
    transform: rotate(45deg) skew(18deg, 18deg);
    border-right: 4px solid #fff;
    border-top: 4px solid #fff;
  }

  .selected-stage {
    background-color: #4caf50;
    .arrow-box {
      background-color: #4caf50;
    }
    i {
      color: white;
    }
  }
  &.red {
    .selected-stage,
    .pipe-line-box {
      background-color: red;
      .arrow-box {
        background-color: red;
      }
    }
  }
}

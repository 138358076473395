.sequence-email-editor-modal {
  .draft-js-container {
    .draft-js-advanced .bf-container {
      flex-direction: column;
      .DraftEditor-editorContainer {
        min-height: 265px;
      }
    }
  }
}

.editable-input-field-form {
  .ant-form-item {
    margin-bottom: 0px !important;
  }
  &.display-mode {
    .ant-input,
    .ant-input-number,
    .ant-select-selector,
    .PhoneInput {
      border: none !important;
      // cursor: pointer !important;
      // background: transparent !important;
      // color: #000000 !important;
      &:hover {
        --tw-bg-opacity: 1 !important;
        background-color: rgba(237, 242, 247, var(--tw-bg-opacity)) !important;
      }
      input {
        background: transparent !important;
      }
    }
  }

  // Patch for class not being passed
  .font-semibold {
    .ant-input {
      font-weight: 600 !important;
    }
  }
}

.mode-dark {
  .editable-input-field-form {
    &.display-mode {
      .ant-input,
      .PhoneInput {
        // color: #ffffff !important;
        &:hover {
          background-color: #2d3748 !important;
        }
      }
    }
  }
}
